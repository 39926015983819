import request from '@/utils/request'

export function getIntegralList(data) {
  return request({
    url: '/marketing-web-api/admin/integral/list',
    method: 'POST',
    data,
  })
}

export function deleteIntegralDetail(data) {
  return request({
    url: '/marketing-web-api/admin/integral/delete',
    method: 'POST',
    data,
  })
}

export function submitIntegralSetup(data) {
  return request({
    url: 'marketing-web-api/admin/integral/config/save',
    method: 'POST',
    data,
  })
}

export function getIntegralSetupDetail(data) {
  return request({
    url: 'marketing-web-api/admin/integral/config/detail',
    method: 'POST',
    data,
  })
}

export function getIntegralRuleDetail(data) {
  return request({
    url: 'marketing-web-api/admin/integral/rule/detail',
    method: 'POST',
    data,
  })
}

export function submitIntegralRule(data) {
  return request({
    url: 'marketing-web-api/admin/integral/rule/save',
    method: 'POST',
    data,
  })
}

export function getIntegralCategoryList(data) {
  return request({
    url: 'marketing-web-api/admin/integral/category/list',
    method: 'POST',
    data,
  })
}

export function getIntegralCategoryPageList(data) {
  return request({
    url: 'marketing-web-api/admin/integral/category/pageList',
    method: 'POST',
    data,
  })
}

export function updateIntegralCategoryStatus(data) {
  return request({
    url: 'marketing-web-api/admin/integral/category/enable',
    method: 'POST',
    data,
  })
}

export function getIntegralDetailPageList(data) {
  return request({
    url: 'marketing-web-api/admin/integral/detail/pageList',
    method: 'POST',
    data,
  })
}

export function exceptionHandingIntegralDetail(data) {
  return request({
    url: 'marketing-web-api/admin/integral/detail/exceptionHanding',
    method: 'POST',
    data,
  })
}
