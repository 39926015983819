<template>
  <div class="integralDetail">
    <el-form :inline="true" :model="searchForm" class="search-form">
      <el-form-item label="用户：">
        <el-input
          v-model.trim="searchForm.userName"
          class="input"
          size="small"
          placeholder="请输入用户昵称"
        ></el-input>
      </el-form-item>

      <el-form-item label="电话号码：">
        <el-input
          v-model.trim="searchForm.userPhone"
          class="input"
          size="small"
          placeholder="请输入电话号码"
        ></el-input>
      </el-form-item>

      <el-form-item label="收款订单号：">
        <el-input
          v-model.trim="searchForm.paymentOrderNo"
          class="input"
          size="small"
          placeholder="请输入收款订单号"
        ></el-input>
      </el-form-item>

      <el-form-item label="状态：">
        <el-select
          v-model="searchForm.sendStatus"
          class="input"
          size="small"
          placeholder="请选择状态"
          style="width: 80px"
        >
          <el-option
            v-for="item in sendStatusOptions"
            :key="item.value"
            size="small"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="积分类型：">
        <KeySelect
          :val.sync="searchForm.integralCategoryDictionaryValue"
          :req-key="isJPG ? 'jpg_integral_category' : 'dpll_integral_category'"
        />
      </el-form-item>

      <el-form-item label="积分行为：">
        <KeySelect
          :val.sync="searchForm.integralBehaviorDictionaryValue"
          :req-key="isJPG ? 'jpg_integral_behavior' : 'dpll_integral_behavior'"
        />
      </el-form-item>

      <el-form-item label="获取时间：">
        <el-date-picker
          v-model="getTimeArr"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          class="button"
          type="primary"
          size="small"
          style="margin-left: 0px"
          @click="getPageList"
        >
          查询
        </el-button>

        <el-button size="small" class="button" icon="el-icon-refresh-left" @click="resetSearch"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <div style="margin-top: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        :header-cell-style="{ 'background-color': '#f5f7fa' }"
        size="small"
      >
        <el-table-column label="序号" type="index" width="60px"> </el-table-column>
        <el-table-column prop="userId" label="用户ID"> </el-table-column>
        <el-table-column prop="userName" label="用户昵称"> </el-table-column>
        <el-table-column prop="userPhone" label="电话号码"> </el-table-column>
        <el-table-column prop="paymentOrderNo" width="236px" label="收款订单号"> </el-table-column>
        <el-table-column prop="orderMoney" label="订单金额"> </el-table-column>

        <el-table-column prop="vipLevelDictionaryName" label="会员等级"> </el-table-column>

        <el-table-column label="获取类型">
          <template slot-scope="scope">
            <span>{{ scope.row.getType == 0 ? '系统' : '手动' }}发放</span>
          </template>
        </el-table-column>

        <el-table-column prop="integralCategoryDictionaryName" label="积分类型"> </el-table-column>
        <el-table-column prop="integralBehaviorDictionaryName" label="积分行为"> </el-table-column>
        <el-table-column prop="obtainIntegral" label="积分明细"> </el-table-column>
        <el-table-column prop="beforeTotalIntegral" label="原账户余额"> </el-table-column>
        <el-table-column prop="totalIntegral" label="变更后余额"> </el-table-column>
        <el-table-column prop="createTime" label="获取时间"> </el-table-column>
        <el-table-column prop="failureTime" label="失效时间"> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="!scope.row.sendStatus" style="color: #00a199">成功</span>
            <span v-else style="color: #cf7b6b">失败</span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="130px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.sendStatus == 1"
              type="text"
              size="small"
              @click="processItem(scope.row, 1)"
            >
              处理
            </el-button>

            <el-button
              v-if="scope.row.sendRemark && scope.row.isDispose == 1"
              type="text"
              size="small"
              @click="processItem(scope.row, 2)"
            >
              处理详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer-row">
        <div class="el-pagination">
          <el-pagination
            background
            layout="->,total,prev, pager, next,sizes"
            :total="total"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="10"
            :current-page.sync="pageNumber"
            @size-change="pageSizeChange"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 后期需求变更备用 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="120px"
    >
      <el-form
        ref="EditForm"
        label-position="right"
        label-width="130px"
        :model="editForm"
        size="small"
        :rules="editFormRules"
      >
        <template v-if="!disabled">
          <el-form-item label="是否补录：" prop="isSupplement">
            <el-radio-group v-model="editForm.isSupplement">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <template v-if="editForm.isSupplement == 1">
            <el-form-item label="发放类型：" prop="incomeType">
              <el-radio-group v-model="editForm.incomeType">
                <el-radio :label="0">积分增加</el-radio>
                <el-radio :label="1">积分减少</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="积分数量：" prop="obtainIntegral">
              <el-input-number v-model="editForm.obtainIntegral" :min="1"></el-input-number>
            </el-form-item>
          </template>

          <el-form-item v-if="editForm.isSupplement == 1" label="补录原因：" prop="sendRemark">
            <el-input
              v-model="editForm.sendRemark"
              type="textarea"
              :rows="4"
              placeholder="请输入补录原因"
              style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="editForm.isSupplement == 0" label="拒绝补录原因：" prop="sendRemark">
            <el-input
              v-model="editForm.sendRemark"
              type="textarea"
              :rows="4"
              placeholder="请输入拒绝补录原因"
              style="width: 400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" @click="submit">确定</el-button>
            <el-button @click="cacnel">取消</el-button>
          </el-form-item>
        </template>

        <template v-else>
          <el-form-item label="处理人：">
            <span>{{ currentItem.updateUserName }}</span>
          </el-form-item>

          <el-form-item label="处理时间：">
            <span>{{ currentItem.updateTime }}</span>
          </el-form-item>

          <el-form-item label="异常原因：">
            <span>{{ currentItem.sendRemark }}</span>
          </el-form-item>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getIntegralDetailPageList, exceptionHandingIntegralDetail } from '@/api/integral'

export default {
  name: '',
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      sendStatusOptions: [
        { value: '', label: '全部' },
        { value: '0', label: '成功' },
        { value: '1', label: '失败' },
      ],
      searchForm: {
        userName: '',
        userPhone: '',
        paymentOrderNo: '',
        sendStatus: '',
        integralCategoryDictionaryValue: '',
        integralBehaviorDictionaryValue: '',
        createBeginTime: '',
        createEndTime: '',
      },
      tableData: [],
      dialogVisible: false,
      dialogTitle: '积分发放异常处理',
      currentItem: {},
      editForm: {
        id: '',
        isSupplement: 1,
        incomeType: 0,
        obtainIntegral: '',
        sendRemark: '',
      },
      editFormRules: {
        isSupplement: {
          required: true,
          message: '请选择是否补录',
          trigger: 'change',
        },
        incomeType: {
          required: true,
          message: '请选择积分增减',
          trigger: 'change',
        },
        obtainIntegral: {
          required: true,
          message: '请输入积分',
          trigger: 'blur',
        },
        sendRemark: { required: true, message: '请输入原因', trigger: 'blur' },
      },
      getTimeArr: [],
      disabled: false,
    }
  },
  computed: {
    isJPG() {
      return this.$route.query.bizLineId === '2'
    },
  },
  watch: {
    getTimeArr(newVal) {
      if (newVal && newVal.length > 1) {
        this.searchForm.createBeginTime = newVal[0]
        this.searchForm.createEndTime = newVal[1]
      } else {
        this.searchForm.createBeginTime = ''
        this.searchForm.createEndTime = ''
      }
    },
  },
  mounted() {
    this.getPageList()
  },
  methods: {
    getPageList() {
      const reqObj = {
        data: {
          ...this.searchForm,
        },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }

      getIntegralDetailPageList(reqObj)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.content || []
            this.total = Number(res.data.total)
          }
        })
        .catch((err) => console.log(err))
    },

    resetSearch() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.getTimeArr = []
      this.pageNumber = 1
      this.pageSize = 10
      this.getPageList()
    },

    pageSizeChange(val) {
      this.pageSize = val
      this.getPageList()
    },

    pageChange(val) {
      this.pageNumber = val
      this.getPageList()
    },
    processItem(row, type) {
      if (type === 1 || type === 2) {
        this.dialogVisible = true

        Object.keys(this.editForm).forEach((key) => {
          this.editForm[key] = typeof this.editForm[key] === 'number' ? 0 : ''
        })

        this.$nextTick(() => {
          this.$refs.EditForm.clearValidate()
          this.dialogTitle = type === 1 ? '积分发放异常处理' : '积分发放异常处理详情'
          this.currentItem = type === 1 ? {} : { ...row }
          this.disabled = type === 2
          this.editForm.id = row.id
          this.editForm.isSupplement = 1
        })
      }
    },
    submit() {
      this.$refs.EditForm.validate((valid) => {
        if (!valid) return

        exceptionHandingIntegralDetail({ data: this.editForm })
          .then((res) => {
            if (res.success) {
              this.getPageList()
              this.$message.success('操作成功')
            }

            this.cacnel()
          })
          .catch((err) => console.log(err.message || err))
      })
    },
    cacnel() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.integralDetail {
  background-color: #fff;
  padding: 20px 20px 20px 20px;

  .search-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    .search-label {
      font-size: 14px;
      margin-left: 20px;
    }

    .input {
      width: 200px;
    }

    .button {
      margin-left: 20px;
    }
  }

  .confirmButton {
    margin-left: 10px;
  }

  .footer-row {
    display: flex;
    margin-top: 20px;

    .el-pagination {
      width: 100%;
    }
  }
}
</style>
